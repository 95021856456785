import React from "react"
import styled from "styled-components"
import TimelineSvg from "../../assets/timeline-svg.svg"
import { device } from "../../styles/BreakPoints"
import { Container } from "../../styles/Global"

const PageHeaderStyle = styled.div`
  height: 310px;
  background: transparent linear-gradient(271deg, #481a72 0%, #57298b 100%);
  box-shadow: 0px 10px 24px #7e7e7e63;
  display: grid;
  place-items: center;
  @media ${device.mobileL} {
    height: 240px;
  }
  .timeline-svg {
    height: 220px;
    width: 440px;
    @media ${device.tablet} {
      width: 300px;
    }
    @media ${device.mobileL} {
      height: 150px;
      width: 240px;
    }
  }
`

const HeaderTitle = styled.h2`
  font-size: 40px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 0.5px 0.87px 1px #0000001a;
  @media ${device.mobileL} {
    font-size: 28px;
    font-weight: 600;
    margin-top: 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobileL} {
    flex-direction: column-reverse;
  }
`

function PageHeader({ title }) {
  return (
    <PageHeaderStyle>
      <Container>
        <ContentContainer>
          <HeaderTitle>{title}</HeaderTitle>
          <TimelineSvg className="timeline-svg" />
        </ContentContainer>
      </Container>
    </PageHeaderStyle>
  )
}

export default PageHeader
