import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import SEO from "../components/SEO"
import TimelineCard from "../components/TimelineCard"
import { timelineData } from "../site-data/timeline"

const TimelineContainer = styled.div`
  color: #010b40;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  background: transparent
    linear-gradient(
      180deg,
      rgba(177, 175, 245, 0.37),
      rgba(255, 255, 255, 0.37)
    );
  &::after {
    background-color: #e17b77;
    content: "";
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100% !important;
  }
`

const Timeline = () => {
  return (
    <Layout nav back footer mb>
      <SEO />
      <PageHeader title="My Journey" />
      <TimelineContainer>
        {timelineData.map((data, idx) => (
          <TimelineCard data={data} key={idx} />
        ))}
      </TimelineContainer>
    </Layout>
  )
}

export default Timeline
