// const bgColors = ['#DFF4D9', '#EBE8F2', '#FDE0C3', '#DFF3F7', '#F8F2DE', '#FEE1D3']

const timelineData = [
  {
    text: "Published my article site build with Gatsbyjs",
    time: "May 26, 2022",
    category: {
      tag: "project",
      bgColor: "#FEE1D3",
    },
    link: {
      url: "https://www.codevertiser.com/",
      text: "Check it out",
    },
  },
  {
    text: "Finished first MVP of passion project Color Contrast Collection",
    time: "February 04, 2022",
    category: {
      tag: "project",
      bgColor: "#EBE8F2",
    },
    link: {
      url: "https://color-contrast-collection.netlify.app/",
      text: "Check it out",
    },
  },
  {
    text: "Acquired a desk in workspace to start my own setup",
    time: "November 04, 2021",
    category: {
      tag: "Office",
      bgColor: "#DFF4D9",
    },
  },
  {
    text: "Quit my job to start my own",
    time: "October 25, 2021",
    category: {
      tag: "job",
      bgColor: "#F8F2DE",
    },
  },
  {
    text: 'Awarded Top 7 badge for having at least one post featured in the weekly "must-reads" from Dev.to',
    time: "March 17, 2021",
    category: {
      tag: "award",
      bgColor: "#FFFAD1",
    },
    link: {
      url: "https://dev.to/abdulbasit313",
      text: "Profile link",
    },
  },
  {
    text: "Started working as MERN Stack Developer at Orange Fox Labs",
    time: "October 08, 2020",
    category: {
      tag: "job",
      bgColor: "#FEE1D3",
    },
    link: {
      url: "https://orangefoxlabs.com/",
      text: "Check it out",
    },
  },
  {
    text: "Deployed first pwa and typescript app",
    time: "August 24, 2020",
    category: {
      tag: "Web",
      bgColor: "#DFF3F7",
    },
    link: {
      url: "https://pwa-pomodoro-clock.netlify.app/",
      text: "Check it out",
    },
  },
  {
    text: "Hired as React Developer",
    time: "January 11, 2020",
    category: {
      tag: "job",
      bgColor: "#F8F2DE",
    },
  },
  {
    text: "98-381: MTA: Introduction to Programming using Python",
    time: "Sepetember 20, 2019",
    category: {
      tag: "certification",
      bgColor: "#EBE8F2",
    },
    link: {
      url: "https://www.youracclaim.com/badges/169bb432-6ebd-499b-805f-891155a243dd/linked_in_profile",
      text: "See credential",
    },
  },
  {
    text: "Published first article on Dev.to",
    time: "March 23, 2019",
    category: {
      tag: "Dev.to",
      bgColor: "#0a0a0a",
      color: "#fff",
    },
    link: {
      url: "https://dev.to/abdulbasit313",
      text: "See profile",
    },
  },
  {
    text: "completed the freeCodeCamp.org Responsive Web Design",
    time: "August 1, 2018",
    category: {
      tag: "certification",
      bgColor: "#DFF4D9",
    },
    link: {
      url: "https://www.freecodecamp.org/certification/basit_miyanji/responsive-web-design",
      text: "Check it out here",
    },
  },
]

export { timelineData }
