import React from 'react'
import styled from 'styled-components'
import { device } from '../../styles/BreakPoints'

const TimelineCardStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin: 10px 0;
  width: 50%;
  @media ${device.tablet} {
    padding-right: 22px;
    }
  &:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-right: 0;
  padding-left: 30px;
  @media ${device.tablet} {
    padding-left: 22px;
    }
  .timeline-item-content {
  text-align: left;
  align-items: flex-start;
  @media ${device.tablet} {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
    }
  &::after {
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
  right: auto;
  left: -7.5px;
}
.item-tag {
  left: auto;
  right: 5px;
}
.circle {
  right: auto;
  left: -40px;
  @media ${device.tablet} {
    left: -32px;
    }
}
}
}
  .timeline-item-content {
    background-color: #fff;
    /* max-width: 70%; */
    max-width: 400px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    text-align: right;
    @media ${device.tablet} {
    padding: 15px 10px;
    text-align: center;
    align-items: center;
    }
    &::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  transform: rotate(45deg);
  position: absolute;
  width: 15px;
  height: 15px;
  right: -7.5px;
  top: calc(50% - 7.5px);
}
.item-tag {
  color: #4d4d4d;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px;
  position: absolute;
  letter-spacing: 1px;
  top: 5px;
  left: 5px;
  @media ${device.tablet} {
    width: calc(100% - 10px);
    }
}
time {
  color: #777;
  font-size: 12px;
  @media ${device.tablet} {
    margin-top: 24px;
    }
}
p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 270px;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 18px;
    }
}
a {
  color: rgb(160, 160, 160);
  font-size: 14px;
  text-decoration: none;
  @media ${device.tablet} {
      text-decoration: underline;
    }
  &::after {
    content: " ►";
    font-size: 12px;
    @media ${device.tablet} {
      display: none;
      
    }
  }
}
.circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
  @media ${device.tablet} {
    right: -32px;
    }
}
  }
`

const TimelineCard = ({ data }) => (
  <TimelineCardStyle className='timeline-item'>
    <div className='timeline-item-content'>
      <span className='item-tag' style={{ backgroundColor: data.category.bgColor, color: data.category.color ? data.category.color : '#4d4d4d' }}>
        {data.category.tag}
      </span>
      <time>{data.time}</time>
      <p>{data.text}</p>
      {data.link && <a href={data.link.url} target='_blank' rel='noopener noreferrer'>
        {data.link.text}
      </a>}
      <span className='circle'></span>
    </div>
  </TimelineCardStyle>
)


export default TimelineCard